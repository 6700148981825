import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import TitleText from "./title-text"
import PageHeading from "../../utils/page-heading"

const AboutUsIntro = () => {
  return (
    <>
      <StaticImage
        src="../../../images/AboutPage.png"
        placeholder="blurred"
        className="w-full"
        alt='About Us'
      />

      <PageHeading text="We are telling the stories of designers across Africa" />
      <div className="mb-24">
        <p>
          Starting out as a simple catchphrase from an article in 2017 to
          becoming an educative series for designers on Twitter, DearDesigner
          has gone through a colourful evolution to become a full-fledged
          non-profit organization in the design industry in Nigeria and Africa
          at large. As an organization, we are committed to the growth of design
          and designers in Africa.
        </p>

        <p>
          We also aim at making the African designer maximize the experience,
          insights, and knowledge within the community to eventually lead a
          better career. Our journey in the documentation of design across
          Africa was inspired by a need to facilitate the curation of the
          beautiful and inspiring stories within the design community. Although
          our journey started in Nigeria, we are currently on a journey of
          expansion to the larger African community.
        </p>

        <p>
          Understanding that design, in its purest form, is simply an
          intentional attempt to solving problems, our work focuses on the
          designer as a problem solver and his entire career. Also, as part of
          our commitment to designers and the entire design community, we also
          facilitate sharing of resources and opportunities within the
          community.
        </p>
      </div>

      <div id="mission">
        <TitleText
          title="Our Mission"
          text="To facilitate the sharing of inspiring design stories from Africa and fostering industry-wide development through various initiatives and partnerships."
        />
      </div>
    </>
  )
}

AboutUsIntro.propTypes = {}

export default AboutUsIntro
