import React from "react"
import TeamMember from "./member"
import TitleText from "../title-text"
import { graphql, useStaticQuery } from "gatsby"

const DearDesignerTeam = () => {
  const team_members = useStaticQuery(graphql`
    query {
      allWpTeamMember(sort: { fields: date, order: ASC }) {
        edges {
          node {
            title
            Team {
              role
            }
          }
        }
      }
    }
  `)

  const show_team_members = team_members.allWpTeamMember.edges.map(
    (team_member, index) => (
      <TeamMember
        name={team_member.node.title}
        role={team_member.node.Team.role}
      />
    )
  )
  return (
    <section id="people">
      <TitleText
        title="Team"
        text="DearDesigner is curated by an amazing team of volunteers. We are
        currently building several means to reach out to more designers in our
        community."
      />
      <ol className="grid gap-x-7 grid-cols-2 ">{show_team_members}</ol>
    </section>
  )
}

DearDesignerTeam.propTypes = {}

export default DearDesignerTeam
