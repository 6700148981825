import React from "react"
import PagesLayout from "../components/layout/pages"
import AboutUsIntro from "../components/pages/about-us/intro"
import DearDesignerTeam from "../components/pages/about-us/team"
import PartnerBrands from "../components/pages/about-us/partner-brands"
import WhatWeDo from "../components/pages/about-us/what-we-do"

const AboutUsPage = () => {
  return (
    <PagesLayout title="About us">
      <>
        <AboutUsIntro />
        <WhatWeDo />
        <DearDesignerTeam />
        <PartnerBrands />
      </>
    </PagesLayout>
  )
}

export default AboutUsPage
