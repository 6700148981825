import React from "react"
import TitleText from "./title-text"

const WhatWeDo = () => {
  const activities = [
    {
      name: "Blog",
      description:
        "Our blog features several engaging posts ranging from articles, profiles, case studies, and other special features. ",
    },
    {
      name: "Mentorship",
      description:
        "The mentorship is designed to help designers stand on the shoulder of more experienced ones through a time-based and well designed program. ",
    },
    {
      name: "Events & Initiatives",
      description:
        "We also host special events individually and in partnership with other organizations to facilitate conversations and training on design and related concepts.",
    },
    {
      name: "Training & Consultancy",
      description:
        "With carefully curated faculty of experts, we work with organizations and teams (Design and non-Design) to help facilitate trainings on areas relating to design. We also consult on hiring of design talents.",
    },
    
  ]

  const events = [
    {
      name: "Social Media Week Lagos",
      description:
        "where we featured Feyi Olubodun, Noella Ekezie, Ted Oladele and Harry Dunkwu in a conversation on Design for the Nigerian. The conversation was moderated by Ayomide Ajayi John.",
    },
    {
      name: "A 5-part live Design session",
      description:
        "with Pelumi Adeyemi, Alabi Mayowa, Mary Afolabi, Niyi Okeowo, Rowland Olamide, Praise Philemon.",
    },
    {
      name: "A bi-monthly physical meetup",
      description:
        "in Abuja hosted for 2 months pre-COVID by Simeon Fadahunsi ",
    },
  ]

  const showActivities = activities.map((activity, index) => (
    <>
      <li key={index} className="font-bold mt-8">
        {activity.name}
      </li>
      <p className="mt-0 ">{activity.description}</p>
    </>
  ))

  const showEvents = events.map((event, index) => (
    <li key={index} className="mt-6">
      <b>{event.name}</b> {event.description}
    </li>
  ))
  return (
    <div className="md:mt-24 mt-0 md:mb-24 mb-12">
      <TitleText
        title="What We Do"
        text="At DearDesigner, our activities covers a range of platforms including;"
      />
      <ol className="list-disc list-inside">{showActivities}</ol>
      <p>Some of our past events include:</p>
      <ol className="list-disc pl-4">{showEvents}</ol>
    </div>
  )
}

export default WhatWeDo
