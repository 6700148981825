import React from "react"

const TeamMember = ({ name, role }) => {
  return (
    <li>
      <p className="md:text-p18 text-p14  font-regular text-dd-black-80">
        {name}
      </p>
      <p className="md:text-p14 text-p12 mt-[0px] text-dd-black-60 font-regular">
        {role}
      </p>
    </li>
  )
}

export default TeamMember
