import React from "react"
import TitleText from "../title-text"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PartnerBrands = () => {
  const partners = useStaticQuery(graphql`
    query {
      allWpPartner {
        edges {
          node {
            title
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const show_partners = partners.allWpPartner.edges.map(partner => (
    <GatsbyImage
      image={getImage(
        partner.node.featuredImage.node.localFile.childImageSharp
      )}
      alt={partner.node.title}
      className="block "
    />
  ))

  return (
    <div className="my-24" id="partners">
      <TitleText
        title="Partner Brands"
        text="Over the past few years, we have been associated with and worked with some amazing organizations in different capacities. Some of our amazing partners include;"
      />

      <section className="flex flex-wrap md:mt-28 mt-12 items-center">
        {show_partners}
      </section>
    </div>
  )
}

export default PartnerBrands
